<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-uppercase">
            REGIÓN
          </th>
          <th class="text-center text-uppercase">
            EN TRÁNSITO
          </th>
          <th class="text-center text-uppercase">
            ENTREGADO EVIDENCIA EN PORTEO
          </th>
          <th class="text-center text-uppercase">
            POR EMBARCAR
          </th>
          <th class="text-center text-uppercase">
            RECHAZO CERRADO
          </th>
          <th class="text-center text-uppercase">
            ENTREGADO CON EVIDENCIA EN TRÁNSITO
          </th>
          <th class="text-center text-uppercase">
            RECHAZO ABIERTO
          </th>
          <th class="text-center text-uppercase">
            TOTAL
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in region"
          :key="item.nombre"
        >
          <!-- <td>{{ item.nombre }}</td>
          <td class="text-center">
            {{ item.enTransito }}
          </td>
          <td class="text-center">
            {{ item.entregadoEvidPorteo }}
          </td>
          <td class="text-center">
            {{ item.porEmbarcar }}
          </td>
          <td class="text-center">
            {{ item.rechazoCerrado }}
          </td>
          <td class="text-center">
            {{ item.entregadoEvidTransito }}
          </td>
          <td class="text-center">
            {{ item.rechazoAbierto }}
          </td>
          <td class="text-center">
            {{ item.total }}
          </td> -->
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  setup() {
    const region = [
      {
        nombre: 'MX5002',
        enTransito: 102,
        entregadoEvidPorteo: 390,
        porEmbarcar: 428,
        rechazoCerrado: 4,
        entregadoEvidTransito: 0,
        rechazoAbierto: 2,
        total: 926,
      },
      {
        nombre: 'MX5003',
        enTransito: 68,
        entregadoEvidPorteo: 390,
        porEmbarcar: 324,
        rechazoCerrado: 0,
        entregadoEvidTransito: 0,
        rechazoAbierto: 2,
        total: 784,
      },
      {
        nombre: 'MX5004',
        enTransito: 326,
        entregadoEvidPorteo: 117,
        porEmbarcar: 24,
        rechazoCerrado: 1,
        entregadoEvidTransito: 108,
        rechazoAbierto: 7,
        total: 583,
      },
      {
        nombre: 'MX5006',
        enTransito: 190,
        entregadoEvidPorteo: 680,
        porEmbarcar: 336,
        rechazoCerrado: 8,
        entregadoEvidTransito: 2,
        rechazoAbierto: 4,
        total: 1220,
      },
    ]

    return {
      region,
    }
  },
}
</script>
