<template>
  <v-card>
    <v-card-title class="align-start">
      <p class="primary--text tituloCatalogo">Seguimiento</p>
      <v-spacer></v-spacer>
      <p class="">Ciclo en progreso: <span>Diciembre 2021</span></p>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-progress-linear v-model="progress.knowledge" height="25" color="success">
            <strong>{{ Math.ceil(progress.knowledge) }}%</strong>
          </v-progress-linear>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiFormSelect,
} from '@mdi/js'

export default {
  data() {
    return {
      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiFormSelect,
      },
      progress: {
        skill: 20,
        knowledge: 0,
        power: 78,
      },
    }
  },
}
</script>
<style>
.tituloCatalogo {
  font-size: 1.5em !important;
}
.h5Facturas {
  font-size: 1.2em;
}
</style>
