<!--Estatus general-->
<template>
  <div id="chart">
    <apexchart type="bar" height="500" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import es from 'apexcharts/dist/locales/es.json'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [
        /* {
          name: 'Entregados',
          data: [64, 23, 70, 49, 51, 70, 68, 52, 35, 27, 14, 45, 49, 29, 3, 0, 0],
        },
        {
          name: 'Por entregar',
          data: [64, 24, 79, 67, 86, 121, 126, 115, 104, 81, 44, 149, 168, 108, 46, 36, 24],
        }, */
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 430,
          locales: [es],
          defaultLocale: 'es',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [
            /* 'MORELOS',
            'CHETUMAL',
            'CHIHUAHUA',
            'OAXACA',
            'QUERÉTARO',
            'HERMOSILLO',
            'TAMPICO',
            'LEÓN',
            'TUXTLA GUTIÉRREZ',
            'MEXICALI',
            'AGUASCALIENTES',
            'CANCÚN',
            'TIJUANA',
            'MORELIA',
            'LOS CABOS',
            'GUERRERO',
            'LA PAZ', */
          ],
        },
        colors: ['#09F952', '#09CAF9'],
      },
    }
  },
}
</script>

<style lang="scss">
@media (max-width: 950px) {
  .grafica1 {
    top: 0px !important;
    width: 70%;
    left: 15%;
  }
}
.theme--dark {
  .apexcharts-menu.apexcharts-menu-open {
    background-color: var(--v-bg-secondary-base);
    border-color: var(--v-bg-secondary-base);
  }
}
</style>
