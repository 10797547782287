<!--Estatus de ciclo por porteo-->
<template>
  <div id="chart">
    <apexchart class="graficaCicloPorteo pb-4" type="pie" width="700" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import es from 'apexcharts/dist/locales/es.json'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [/* 686, 1577, 1112, 13, 110, 15 */],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
          locales: [es],
          defaultLocale: 'es',
        },
        /* labels: ['En tránsito', 'Entregado evidencia en porteo', 'Por embarcar', 'Rechazo cerrado', 'Entregado con evidencia en transito', 'Rechazo abierto'], */
        colors: ["#09F952", "#E70956", "#E79D09", "#F9BE09", "#6E09E7", "#0962E7"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
      },

    }
  },
}
</script>

<style lang="scss">
.graficaCicloPorteo{
  position: relative;
  left: 20%;
}

.theme--dark {
  .apexcharts-menu.apexcharts-menu-open {
    background-color: var(--v-bg-secondary-base);
    border-color: var(--v-bg-secondary-base);
  }
}
</style>
