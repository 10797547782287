<template>
  <div>
    <cabecera></cabecera>
    <!--Estatus general-->
    <v-row class="mt-4">
      <v-col cols="12">
        <v-card>
          <grafica1></grafica1>
        </v-card>
      </v-col>
    </v-row>
    <!--Estatus por porteo-->
    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="mx-auto estatusPorteoCard">
          <v-card-title>
            <p>Estatus específico por porteo</p>
          </v-card-title>
          <v-card-text>
            <tabla1 class="detallesPorteoCard"></tabla1>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mt-4" text color="primary" @click="reveal = true">
              Gráfica
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <v-card v-if="reveal" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
              <v-card-text class="pb-0">
              <grafica2></grafica2>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn class="mt-4" text color="primary" @click="reveal = false">
                  Datos
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <!--Estatus por region-->
    <v-row class="mt-4">
      <v-col cols="12">
        <v-card class="mx-auto estatusRegion">
          <v-card-title>
            <p>Estatus por región</p>
          </v-card-title>
          <v-card-text>
            <tabla2></tabla2>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mt-4" text color="primary" @click="reveal2 = true">
              Gráfica
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <v-card v-if="reveal2" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
              <v-card-text class="pb-0">
              <grafica4></grafica4>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn class="mt-4" text color="primary" @click="reveal2 = false">
                  Datos
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <!--Estatus de ciclo por porteo-->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <p>Estatus de ciclo</p>
          </v-card-title>
          <grafica3 class=""></grafica3>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import cabecera from './Cabecera.vue'
import grafica1 from './Grafica1.vue'
import tabla1 from './Tabla-traking.vue'
import grafica2 from './Grafica2.vue'
import grafica3 from './Grafica3.vue'
import tabla2 from './Tabla-traking2.vue'
import grafica4 from './Grafica4.vue'

export default {
  components: {
    cabecera,
    grafica1,
    tabla1,
    grafica2,
    grafica3,
    tabla2,
    grafica4,
  },
  data(){
    return {
      reveal: false,
      reveal2: false,
    }
  },
}
</script>
<style>
.cardGrafica {
  position: relative;
  min-width: 100% !important;
  height: 97%;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.estatusPorteoCard{
  height: 700px;
}
.estatusRegion{
  height: 500px;
}
</style>
