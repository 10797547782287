<!--Estatus por porteo-->
<template>
  <div id="chart">
        <apexchart type="bar" height="600" :options="chartOptions" :series="series"></apexchart>
      </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import es from 'apexcharts/dist/locales/es.json'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [/* {
        name: 'Cancelada',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      }, {
        name: 'En tránsito',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      }, {
        name: 'Entregado evidencia en porteo',
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      }, {
        name: 'Por embarcar',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      }, {
        name: 'Rechazo cerrado',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      }, {
        name: 'Entregado con evidencia en transito',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      }, {
        name: 'Rechazo abierto',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      }, {
      } */],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          locales: [es],
          defaultLocale: 'es',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        }],
        xaxis: {
          /* categories: ['Morelos', 'Chetumal', 'Chihuahua', 'Oaxaca', 'Querétaro', 'Hermosillo', 'Tampico', 'León', 'Tuxtla Gutiérrez'], */
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'bottom',
          offsetX: 50,
          offsetY: 0,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#09F952", "#E70956", "#E79D09", "#F9BE09", "#6E09E7", "#0962E7", "#09B3E7"],
      },
    }
  },
}

</script>

<style lang="scss">
.theme--dark {
  .apexcharts-menu.apexcharts-menu-open {
    background-color: var(--v-bg-secondary-base);
    border-color: var(--v-bg-secondary-base);
  }
}
</style>
