<!--Estatus por region-->
<template>
   <div id="chart">
        <apexchart class="mt-6" type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import es from 'apexcharts/dist/locales/es.json'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [/* {
        name: 'En tránsito',
        data: [102, 68, 326, 190],
      }, {
        name: 'Entregado evidencia en porteo',
        data: [390, 390, 117, 680],
      }, {
        name: 'Por embarcar',
        data: [428, 324, 24, 336],
      }, {
        name: 'Rechazo cerrado',
        data: [4, 0, 1, 8],
      }, {
        name: 'Entregado evidencia en transito',
        data: [0, 0, 108, 2],
      }, {
        name: 'Rechazo abierto',
        data: [2, 2, 7, 4],
      } */],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          locales: [es],
          defaultLocale: 'es',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['MX5002', 'MX5003', 'MX5004', 'MX5006'],
        },
        yaxis: {
          title: {
            text: 'Facturas',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
    }
  },
}

</script>

<style lang="scss">
.theme--dark {
  .apexcharts-menu.apexcharts-menu-open {
    background-color: var(--v-bg-secondary-base);
    border-color: var(--v-bg-secondary-base);
  }
}
</style>
