<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-uppercase">
            PORTEO
          </th>
          <th class="text-center text-uppercase">
            CANCELADA
          </th>
          <th class="text-center text-uppercase">
            EN TRÁNSITO
          </th>
          <th class="text-center text-uppercase">
            ENTREGADO EVIDENCIA EN PORTEO
          </th>
          <th class="text-center text-uppercase">
            POR EMBARCAR
          </th>
          <th class="text-center text-uppercase">
            RECHAZO CERRADO
          </th>
          <th class="text-center text-uppercase">
            ENTREGADO CON EVIDENCIA EN TRÁNSITO
          </th>
          <th class="text-center text-uppercase">
            RECHAZO ABIERTO
          </th>
          <th class="text-center text-uppercase">
            TOTAL
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in region"
          :key="item.nombre"
        >
          <!-- <td>{{ item.nombre }}</td>
          <td class="text-center">
            {{ item.cancelada }}
          </td>
          <td class="text-center">
            {{ item.enTransito }}
          </td>
          <td class="text-center">
            {{ item.entregadoEvidPorteo }}
          </td>
          <td class="text-center">
            {{ item.porEmbarcar }}
          </td>
          <td class="text-center">
            {{ item.rechazoCerrado }}
          </td>
          <td class="text-center">
            {{ item.entregadoEvidTransito }}
          </td>
          <td class="text-center">
            {{ item.rechazoAbierto }}
          </td>
          <td class="text-center">
            {{ item.total }}
          </td> -->
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  setup() {
    const region = [
      {
        nombre: 'Morelos',
        cancelada: 0,
        enTransito: 0,
        entregadoEvidPorteo: 20,
        porEmbarcar: 24,
        rechazoCerrado: 0,
        entregadoEvidTransito: 0,
        rechazoAbierto: 0,
        total: 44,
      },
      {
        nombre: 'Chetumal',
        cancelada: 0,
        enTransito: 0,
        entregadoEvidPorteo: 20,
        porEmbarcar: 24,
        rechazoCerrado: 0,
        entregadoEvidTransito: 0,
        rechazoAbierto: 0,
        total: 44,
      },
      {
        nombre: 'Chihuahua',
        cancelada: 0,
        enTransito: 0,
        entregadoEvidPorteo: 20,
        porEmbarcar: 24,
        rechazoCerrado: 0,
        entregadoEvidTransito: 0,
        rechazoAbierto: 0,
        total: 44,
      },
      {
        nombre: 'Oaxaca',
        cancelada: 0,
        enTransito: 0,
        entregadoEvidPorteo: 20,
        porEmbarcar: 24,
        rechazoCerrado: 0,
        entregadoEvidTransito: 0,
        rechazoAbierto: 0,
        total: 44,
      },
      {
        nombre: 'Querétaro',
        cancelada: 0,
        enTransito: 0,
        entregadoEvidPorteo: 20,
        porEmbarcar: 24,
        rechazoCerrado: 0,
        entregadoEvidTransito: 0,
        rechazoAbierto: 0,
        total: 44,
      },
      {
        nombre: 'Hermosillo',
        cancelada: 0,
        enTransito: 0,
        entregadoEvidPorteo: 20,
        porEmbarcar: 24,
        rechazoCerrado: 0,
        entregadoEvidTransito: 0,
        rechazoAbierto: 0,
        total: 44,
      },
      {
        nombre: 'Tampico',
        cancelada: 0,
        enTransito: 0,
        entregadoEvidPorteo: 20,
        porEmbarcar: 24,
        rechazoCerrado: 0,
        entregadoEvidTransito: 0,
        rechazoAbierto: 0,
        total: 44,
      },
      {
        nombre: 'León',
        cancelada: 0,
        enTransito: 0,
        entregadoEvidPorteo: 20,
        porEmbarcar: 24,
        rechazoCerrado: 0,
        entregadoEvidTransito: 0,
        rechazoAbierto: 0,
        total: 44,
      },
      {
        nombre: 'Tuxtla Gutiérrez',
        cancelada: 0,
        enTransito: 0,
        entregadoEvidPorteo: 20,
        porEmbarcar: 24,
        rechazoCerrado: 0,
        entregadoEvidTransito: 0,
        rechazoAbierto: 0,
        total: 44,
      },
    ]

    return {
      region,
    }
  },
}
</script>
